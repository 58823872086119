@use "../../../../global-colours";

$category-background-colour: global-colours.$dark-grey-colour;
$property-background-colour: #2c2b6b;
$station-bar-background-colour: #2c2b6b;
$date-bar-background-colour: #2c2b6b;
$loader-border-colour: #3c3c3c;
$week-divider-background-colour: #2c2b6b;
$calendar-filler-background-colour: #2c2b6b;
$activity-border-colour: #2c2b6b;
$button-background-colour: #2c2b6b;
$calendar-datebar-border-colour: #dee2e6;

$unavailable-activity-background-colour: lightgrey;
$pending-activity-background-colour: #73bf4c;
$unconfirmed-sold-activity-background-colour: #e0e000;
$sold-activity-background-colour: #ef5252;
$guaranteed-activity-background-colour: #22a0ce;
$onhold-activity-background-colour: #684579;


