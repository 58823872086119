/* Kendo variables must be set BEFORE importing styles
@use "../node_modules/@progress/kendo-theme-material/scss/badge/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/button/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/calendar/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/checkbox/_index.scss";f
@use "../node_modules/@progress/kendo-theme-material/scss/combobox/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/common/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/dateinput/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/datepicker/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/dropdownlist/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/floating-label/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/forms/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/input/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/listbox/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/menu/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/popup/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/scheduler/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/textarea/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/textbox/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/timepicker/_index.scss";
@use "../node_modules/@progress/kendo-theme-material/scss/tooltip/_index.scss";
 */
h3 {
  font-size: 1rem !important;
  color: rgb(63, 81, 181);
  margin: 15px 0 15px 0 !important;
  text-align: center;
}

.menu, .main-menu {
  color: #3f51b5 !important;
  background-color: transparent !important;
}

.main-menu {
  height: 35px !important;
  border-bottom: 2px solid #3f51b5 !important;
  align-items: center;
}

.main-menu .mat-mdc-button {
  width: 100% !important;
  text-align: left !important;
  color: #3f51b5 !important;
}

.menu .mdc-button, .main-menu .mdc-button {
  height: 50px !important;
  font-size: 1rem !important;
  color: inherit !important;
  text-align: left !important;
}

.selected-menu {
  border-top: 2px solid !important;
  background-color: #fafafa !important;
  border-bottom: none !important;
  padding-bottom: 2px;
}

.mat-nav-list .mat-mdc-button {
  width: 100% !important;
  text-align: left !important;
}

.mat-drawer {
  color: #3f51b5 !important;
}

.floatinglabel .k-label {
  font-size: 0.8rem !important;
}

.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
  align-self: baseline !important;
  display: inline-block !important;
}

.mdc-evolution-chip__checkmark {
  display: none !important;
}

.mdc-evolution-chip__graphic {
  width: 0 !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-tab-body-content {
  overflow: unset !important;
  height: 95% !important;
}

.mdc-form-field > label {
  white-space: nowrap !important;
  padding-left: 0 !important;
}

.mat-datepicker-toggle > .mdc-icon-button {
  width: 20px;
  height: 20px;
  padding: 0;
  left: -5px;
}

.mdc-data-table__row {
  min-height: 48px !important;
}

.mat-mdc-paginator .mdc-notched-outline {
  height: 22px;
  width: 58px;
  left: -2px;
  top: 10px;
}

.mat-mdc-paginator-range-label {
  height: 50%;
  align-self: center;
}

.mat-mdc-paginator-page-size {
  align-self: flex-end;
}

.mat-mdc-paginator .mat-mdc-select {
  width: 55px;
}

.mat-mdc-paginator-container {
  flex-wrap: nowrap !important;
  font-size: 0.7rem;
}

.mat-mdc-paginator-range-actions {
  display: inline-flex;
  align-items: center;
}

.mat-mdc-paginator-range-actions > .mat-mdc-icon-button {
  width: 35px;
}

.activity-content > .mat-mdc-tab-group > .mat-mdc-tab-header {
  background-color: #f7f7f7 !important;
  border-radius: 5px 5px 0 0 !important;
}

.mat-mdc-header-row .mdc-text-field {
  font-size: 0.7rem !important;
}

.mat-mdc-header-row .mdc-floating-label {
  font-size: 0.7rem !important;
}

.mat-mdc-header-row .mat-mdc-select {
  font-size: 0.7rem !important;
  width: 95%;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) .mdc-menu-surface.mat-mdc-select-panel {
  white-space: nowrap !important;
  min-width: max-content !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  overflow: auto;
}

.mdc-dialog .mdc-dialog__surface {
  padding: 5px;
}

.mdc-dialog__container {
  padding: 5px !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-drawer-inner-container {
  width: 25rem !important;
  background-color: white !important;
  border-right: 3px solid #2c2b6b !important;
  border-top: 2px solid #2c2b6b !important;
  border-bottom: 3px solid #2c2b6b !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  padding: 15px !important;
}

/* BELOW MAKES OUTLINE APPEARANCE MORE DENSE  */
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 1em !important;
}

.mat-mdc-form-field-infix {
  min-height: 48px !important;
}

.mat-mdc-form-field {
  line-height: 1.33 !important;
}

.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: block;
  font-size: 1rem;
}

.mat-mdc-card-content {
  padding: 0 !important;
  margin: 5px;
}

.confirmed-status-conflict .mat-mdc-dialog-container .mdc-dialog__title {
  background-color: #ef5252 !important;
  color: white !important;
}

.confirmed-status-conflict .mat-mdc-dialog-container {
  background-color: #ef5252 !important;
}

.client-catagory-conflict .mat-mdc-dialog-container .mdc-dialog__title {
  background-color: cornflowerblue !important;
  color: white !important;
}

.client-catagory-conflict .mat-mdc-dialog-container {
  background-color: cornflowerblue !important;
}

.unavailable-dates .mat-mdc-dialog-container .mdc-dialog__title {
  background-color: teal !important;
  color: white !important;
}

.unavailable-dates .mat-mdc-dialog-container {
  background-color: teal !important;
}

.final-pcr-date-field > div {
  width: 14.55rem;
  left: -5px;
}

.admin-mat-card-header {
  width: 100% !important;
  display: none !important;
}

.message, .message-error, .message-warning, .message-info, .message-success {
  padding: 10px 20px !important;
  box-sizing: border-box;
  position: relative;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  font-size: 1rem !important;
  line-height: 20px;
  width: 350px;
  white-space: pre-line;
}

.message-success {
  color: #fff;
  background: #8bc34a;
}

.message-info {
  color: #fff;
  background: #03A9F4;
}

.message-warning {
  color: #fff;
  background: #ffdb5b;
}

.message-error {
  color: #fff;
  background: #F44336;
}

.trv-parameters-area .trv-parameters-area-footer {
  top: 0 !important;
  bottom: unset !important;
  height: 4rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.trv-parameters-area.preview .trv-parameters-area-content {
  top: 4rem !important;
  bottom: unset !important;
  height: calc(100% - 4rem) !important;
}

.trv-parameters-area .trv-parameters-area-preview-button {
  top: unset !important;
  left: unset !important;
  background-color: #2c2b6b !important;
  color: white;
  height: 2.25rem;
  width: 7rem;
  border-radius: 5px;
}

.trv-pages-area .trv-page-container {
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
  line-height: 1.25 !important;
}

.trv-page-container div {
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
}

.trv-page-container span {
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
}

.popup-class-activity-contact {
  transform: scale(0.8) translate(-12%, -12%) !important;
}

.popup-class-activity {
  width: 400px !important;
}

kendo-scheduler .k-event {
  min-height: 20px !important;
  border-radius: 10px !important;
  height: 20px !important;
}

kendo-scheduler .k-event > div,
.k-event > div {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  width: auto !important;
  line-height: 1.4285714286 !important;
}

kendo-scheduler .k-event .k-event-actions:first-child {
  margin: 1px 4px 0px 8px;
}

.k-floating-label-container.k-state-empty > .k-label, .k-floating-label-container.k-empty > .k-label {
  left: 0;
}

.k-event-actions {
  line-height: 1 !important;
}

.k-event .k-event-delete {
  margin-top: 1px !important;
}

.k-pdf-export .noExport {
  display: none;
}

.k-top-start, .k-pos-top-start {
  top: 3px !important;
  left: 4px !important;
}

.k-textarea {
  width: 100% !important;
  background: transparent !important;
  font-size: 0.9rem !important;
}

.k-textarea > .k-input {
  padding: 0 !important;
  min-height: unset !important;
}

k-textarea:focus-within {
  border: none !important;
  box-shadow: none !important;
}

.k-textarea:focus, .k-textarea.k-state-focus, .k-textarea.k-state-focused {
  border: none !important;
  box-shadow: none !important;
}

.k-textbox-container, .k-floating-label-container {
  width: 100% !important;
}

.k-textbox {
  height: 29px !important;
  font-size: 0.9rem !important;
}

.k-textbox::selection, .k-textbox .k-input::selection {
  background-color: dodgerblue !important;
}

.k-i-clock {
  color: unset;
}

.k-timepicker {
  width: unset !important;
}

.k-picker-wrap {
  height: 29px !important;
}

.k-dateinput-wrap > .k-input {
  height: unset !important;
}

.k-dateinput-wrap > .k-input::selection {
  background-color: dodgerblue !important;
}

.k-scheduler-toolbar {
  color: black;
}

.k-recur-view .k-edit-field {
  width: max-content !important;
}

kendo-popup.k-animation-container {
  width: max-content !important;
}

.k-recur-view .k-edit-field kendo-dropdownlist {
  width: 120px !important;
}

.k-window-titlebar {
  border: 2px solid #4544a9 !important;
  background-color: #4544a9 !important;
  color: white !important;
}

.k-window-titlebar + .k-window-content {
  margin-top: 10px !important;
  overflow: hidden !important;
}

tr.selected,
tr.k-alt.selected {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

tr.unselected,
tr.k-alt.unselected {
  background-color: transparent !important;
}

.splash-control {
  height: calc(100vh - 24px - 50px);
  display: grid;
  grid-template-rows: 50px 50px 1fr 90px;
}

.splash-header {
  display: flex;
  width: 100%;
  height: 65px;
  color: white;
  align-items: center;
  padding: 0 10px;
}

.splash-info {
  margin-top: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-self: center;
  font-weight: bold !important;
  font-size: 1.4rem !important;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
  width: 100%;
  justify-content: center;
}

.splash-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.splash-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold !important;
  font-size: 1.3rem !important;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
}

.loading-msg-image {
  margin-right: 10px;
  height: 35px;
}

html {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
  overflow: hidden;
}

body {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  font-family: "Open Sans", Roboto, "Helvetica Neue", sans-serif !important;
}

.wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: flex;
}

.item {
  -webkit-box-flex: 1 200px;
  -moz-box-flex: 1 200px;
  -webkit-flex: 1 200px;
  flex: 1 200px;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -webkit-order: 2;
  order: 2;
}

.mat-mdc-card-header-text {
  width: 100% !important;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}

.tooltip {
  white-space: pre;
}

.rounded-button {
  border-radius: 10em;
  width: 100%;
  border: 2px solid #4544a9 !important;
  background-color: transparent !important;
  color: #4544a9 !important;
  outline: none;
}

.activities-content > .mat-mdc-tab-group > .mat-mdc-tab-body-wrapper > .mat-mdc-tab-body {
  overflow: inherit !important;
}

.mdc-tab--active {
  background-color: transparent !important;
  opacity: 1 !important;
}

.mdc-tab {
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  height: 48px !important;
  padding: 0 24px !important;
  cursor: pointer !important;
  box-sizing: border-box !important;
  opacity: 0.6;
  min-width: 160px !important;
  text-align: left !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  white-space: nowrap !important;
  position: relative !important;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0;
}

.mdc-text-field {
  padding: 0 !important;
}

.mdc-text-field--filled .mdc-floating-label {
  left: auto !important;
  top: 35px !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  top: 20px !important;
}

.mdc-tab__text-label > div {
  height: 19px !important;
  align-items: center !important;
}

.mdc-tab__text-label > div > div > mat-icon {
  height: 100% !important;
}

.mdc-tab__text-label:first-child:nth-last-child(1) > div > div > mat-icon {
  display: none !important;
}

.mdc-tab__text-label {
  display: inline-flex !important;
  align-items: center !important;
}

button.btn-block {
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s !important;
}

.dual-list .button-bar button {
  width: 50% !important;
  height: 35px !important;
  font-weight: 400 !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
  border-color: transparent !important;
}

div.record-picker::-webkit-scrollbar-thumb {
  background-color: #6c757d !important;
}

.record-picker li.selected {
  background-color: #ebebeb !important;
}

.btn-primary {
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-primary:hover {
  color: white;
  background-color: #868e96;
  border-color: #6c757d;
}

.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
  background-color: #6c757d !important;
}

.mat-mdc-tab-group {
  height: 100% !important;
  width: 100% !important;
}

li[aria-label="Navigate backward"] {
  display: none !important;
}

li[aria-label="Navigate forward"] {
  display: none !important;
}

li[aria-label="Stop rendering"] {
  display: none !important;
}

li[aria-label="Toggle document map"] {
  display: none !important;
}

li[aria-label="Toggle parameters area"] {
  display: none !important;
}

li[aria-label="Send an email"] {
  display: none !important;
}

.scrollbar-cb::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

.scrollbar-cb::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
}

.scrollbar-cb::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.scrollbar-cb::-webkit-scrollbar-corner {
  background-color: transparent;
}

.scrollbar-cb {
  overflow: auto;
  color: #000000;
  transition: color 0.3s;
}
.scrollbar-cb:hover {
  color: #6c757d;
}

.activity-status-wrapper {
  display: flex;
  flex-direction: column;
  border: 2px solid #c0c0c0;
  border-radius: 10px;
  margin-top: 0.95rem;
  height: 114px;
}

.status-header {
  background-color: #6c757d !important;
  color: white !important;
  font-size: 1.1rem !important;
  padding: 5px;
  border-radius: 8px 8px 0 0;
}

.held-date {
  padding: 3px;
  font-size: 1rem;
  width: 9rem;
  border-bottom: none;
  margin-right: 3px;
}

.held-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.held-date-label {
  padding: 3px 10px;
  font-size: 1rem;
}

.pending-activity {
  background-color: #73bf4c;
  color: white;
}

.sold-activity {
  background-color: #ef5252;
  color: white;
}

.guaranteed-activity {
  background-color: #22a0ce;
  color: white;
}

.onhold-activity {
  background-color: #684579;
  color: white;
}

.unconfirmed-sold-activity {
  background-color: #e0e000;
  color: #6c757d;
}

.pending-activity-text {
  background-color: #73bf4c;
  color: white;
}

.sold-activity-text {
  background-color: #ef5252;
  color: white;
}

.guaranteed-activity-text {
  background-color: #22a0ce;
  color: white;
}

.onhold-activity-text {
  background-color: #684579;
  color: white;
}

.unconfirmed-sold-activity-text {
  background-color: #e0e000;
  color: #6c757d;
}

.k-recurrence-editor .k-form-label {
  padding: 0 15px 24px 15px !important;
}

.k-notification-group {
  z-index: 99999 !important;
}

.k-chip-sm {
  font-size: 16px;
}

.k-button-sm {
  padding: 3px 7px;
  font-size: 12px;
}

.k-toolbar {
  padding: 0 10px;
  justify-content: space-between;
}

.k-toolbar .k-grid-add-command {
  position: absolute;
}

.k-grid-header {
  border-bottom-width: 0 !important;
}

.k-grid-header .k-header {
  padding: 0 5px;
  box-sizing: border-box;
}

.k-grid th, .k-grid td {
  padding: 0.2rem 2px 0 3px !important;
  vertical-align: top;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}

.k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked {
  border-right-color: transparent !important;
}

.k-grid .k-grid-header-locked, .k-grid .k-grid-content-locked, .k-grid .k-grid-header-locked .k-header, .k-grid .k-grid-content-locked td {
  border-color: rgba(0, 0, 0, 0.12);
}

.k-grid .k-edit-cell, .k-grid .k-command-cell, .k-grid .k-grid-edit-row td {
  padding: 2px;
  text-overflow: clip;
}

.k-grid-header-locked > table, .k-grid-header-wrap > table {
  margin-bottom: 0px;
}

.k-grid-content tr:last-child > td, .k-grid-content-locked tr:last-child > td {
  border-bottom-width: 1px;
}

.k-input-sm .k-input-inner, .k-picker-sm .k-input-inner {
  padding: 5px;
}

.k-filtercell .k-input, .k-filtercell .k-picker {
  font-size: 0.8rem !important;
}

.k-filtercell .k-icon {
  font-size: 0.8rem !important;
}

kendo-grid-date-filter-cell input {
  padding: 3px !important;
  box-sizing: border-box;
}

kendo-grid-date-filter-cell kendo-dropdownlist > button {
  padding: 2px 4px !important;
  box-sizing: border-box;
}

kendo-grid-date-filter-cell kendo-dropdownlist + button {
  padding: 2px !important;
  margin-left: 2px;
  box-sizing: border-box;
}

.k-input-md .k-input-button, .k-input-md .k-spinner-increase, .k-input-md .k-spinner-decrease, .k-picker-md .k-input-button, .k-picker-md .k-spinner-increase, .k-picker-md .k-spinner-decrease {
  padding: 3px 2px;
}

kendo-checkbox-filter {
  justify-content: center !important;
}

.k-input-sm, .k-picker-sm {
  font-size: 14px;
}

.k-floating-label-container {
  width: 100%;
}

.k-input {
  transform: perspective(10px);
}

.k-dialog-wrapper {
  width: 98% !important;
  margin: 0 1% !important;
}

.k-window-titlebar {
  color: white;
  background-color: #3f51b5;
  border-color: #3f51b5;
  height: 20px;
}

form > .kendo-grid {
  height: 99%;
  margin: 0 auto;
}

kendo-grid {
  height: 95%;
  margin: 0 auto;
}

.kendo-child-grid {
  width: 100%;
}

.kendo-grid-header, .kendo-grid-header-center {
  white-space: pre-wrap !important;
}

.kendo-grid-header-center {
  justify-content: center !important;
  text-align: center !important;
}

.kendo-grid-column-center {
  text-align: center !important;
}

/*@media only screen and (max-width: 1279px)
{
  body
  {
    font-size: 0.9rem !important;
  }
}

@media only screen and (max-width: 1179px)
{
  body
  {
    font-size: 0.85rem !important;
  }
}

@media only screen and (max-width: 1079px)
{
  body
  {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 979px)
{
  body
  {
    font-size: 0.75rem !important;
  }
}

@media only screen and (max-width: 300px)
{
  body
  {
    font-size: 0.7rem !important;
  }
}
*/